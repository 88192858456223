import * as React from "react";
import {
  Modal,
  Button,
  TextField,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import "../style/style.scss";
import Logo from "../images/mdaxxlogo.png";

const IndexPage = () => {
  const [workWith, setWorkWith] = React.useState(false);
  const [workFor, setWorkFor] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [referCode, setReferCode] = React.useState<boolean>(false);
  const [codeError, setCodeError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleClose = () => {
    setCode("");
    setReferCode(false);
    setWorkFor(false);
  };

  const checkRefer = () => {
    setLoading(true);
    fetch(
      "https://classy-buttercream-b5d52d.netlify.app/.netlify/functions/googlesheets",
      {
        method: "POST",
        body: JSON.stringify({ name: code }),
      }
    )
      .then((response: any) => {
        if (response.ok) {
          setReferCode(true);
          setLoading(false);
        } else if (response.status >= 400) {
          setCodeError(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setCodeError(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Container maxWidth="lg">
        <img className="logo shimmer" src={Logo} />
        <div className="buttonWrapper">
          <Button className="mdxButton" onClick={() => setWorkWith(true)}>
            Work with Us
          </Button>
          <Button className="mdxButton" onClick={() => setWorkFor(true)}>
            Work for Us
          </Button>
        </div>
      </Container>

      <Modal
        open={workWith}
        onClose={() => setWorkWith(false)}
        className="modal-wrapper"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container maxWidth="md" className="modal-content">
          <Button onClick={() => setWorkWith(false)} className="closeBtn">
            <span className="icon-close"></span>
          </Button>
          <Typography className="modal-title" variant="h3">
            CONTACT
          </Typography>
          <Typography className="modal-text" variant="body1">
            MDAX provides highly experienced, qualified & ticketed contractors.
            We are not a crew company; we are problem solvers & grafters that
            know our respective trades. We travel, we come equipped and we make
            your goals happen on time.
          </Typography>
          <Typography className="modal-text" variant="body1">
            MDAX PROVIDES:
          </Typography>
          <ul>
            <li>CARPENTERS</li>
            <li>WELDERS</li>
            <li>PLANT OPERATORS</li>
            <li>MEWP OPERATORS</li>
          </ul>
          <Typography className="modal-text" variant="body1">
            Contact us by <a href="mailto:mike@mdaxx.com">email</a>
          </Typography>
        </Container>
      </Modal>
      <Modal
        open={workFor}
        onClose={handleClose}
        className="modal-wrapper"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container maxWidth="md" className="modal-content center-flex">
          <Button onClick={handleClose} className="closeBtn">
            <span className="icon-close"></span>
          </Button>
          {!referCode ? (
            <>
              <div>
                <div className="center-flex referall">
                  <Typography className="modal-inline-title" variant="h5">
                    Enter your referral code:
                  </Typography>
                  <div className="modal-input">
                    <TextField
                      className="input"
                      value={code}
                      onChange={handleChange}
                      color="info"
                    />
                    <Button className="mdxButton" onClick={checkRefer}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                {codeError && (
                  <div className="center-flex error-wrap">
                    <Typography
                      className="modal-error"
                      align="center"
                      variant="body1"
                    >
                      Please enter a valid referal code
                    </Typography>
                  </div>
                )}
              </div>
              {loading && (
                <div className="loading">
                  <CircularProgress />
                </div>
              )}
            </>
          ) : (
            <div className="center-flex">
              <iframe src="https://forms.monday.com/forms/embed/5496671a79fcee479750ac1af0fee211?r=use1" width="650" height="500"></iframe>
            </div>
          )}
        </Container>
      </Modal>
    </>
  );
};

export default IndexPage;

export function Head() {
  return <title>MDAX</title>;
}
